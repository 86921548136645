<template>
  <!-- <section class="chartIntro scroll-spy"> -->
  <section :class="`chartIntro scroll-spy ${aniShow}`">
    <div class="inner">
      <!-- show 붙여둠 -->
      <div class="title center">다양한 데이터 기반의 차트 제공</div>
      <ul class="back-to-position to-top">
        <li class="chartDetail">
          <img src="../assets/chart-sample.png" alt="" />
          <div class="type smallTitle">디폴트</div>
          <div class="description">
            홀드: 단순 홀드 시 자산 변동 율 <br />
            실 수익: 초기 예치 금액 대비 현재 수익 율 <br />
            디믹스 마이닝 온리: 프로그램을 통한 수익 율
          </div>
        </li>
        <li class="chartDetail">
          <img src="../assets/chart-sample.png" alt="" />
          <div class="type smallTitle">옵션</div>
          <div class="description">
            홀드: 단순 홀드 시 자산 변동 율 <br />
            실 수익: 초기 예치 금액 대비 현재 수익 율 <br />
            디믹스 마이닝 온리: 프로그램을 통한 수익 율
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    aniShow: String,
  },
};
</script>
